import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Helmet from "react-helmet";

// design imports
import { Button as MuiButton, Paper, Typography, Input, FormControl, InputLabel } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { login } from "../../store/actions/auth";

const Button = styled(MuiButton)(spacing);
const Wrapper = styled(Paper)`
    padding: ${(props) => props.theme.spacing(6)}px;

    ${(props) => props.theme.breakpoints.up("md")} {
        padding: ${(props) => props.theme.spacing(10)}px;
    }
`;

function SignIn({}: {}): ReactElement {
    const history = useHistory<any>();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    return (
        <Wrapper>
            <Helmet title="Sign In" />

            <Typography component="h1" variant="h4" align="center">
                Welcome!
            </Typography>
            <Typography component="h2" variant="body1" align="center">
                Sign in to your Connect Fitness account to continue.
            </Typography>
            <br />
            <form>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input
                        id="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input
                        name="password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                {/*<FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />*/}
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    mb={2}
                    onClick={() => {
                        login(email, password);
                        history.push("/");
                    }}
                >
                    Sign in
                </Button>
            </form>
        </Wrapper>
    );
}

export default SignIn;
