import { createAction, PayloadAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { Guides } from "noex";
import { EventChannel, eventChannel } from "redux-saga";
import { PutEffect, CallEffect, TakeEffect, put, call, take } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { customTypedFilter } from "../../app/common/util/customFunctions";
import { firestore } from "../../firebase/firebase";
import { RootState } from "../rootReducer/rootReducer";

export const SET_GUIDE_SECTIONS_LISTENER = "SET_GUIDE_SECTIONS_LISTENER";
export const FETCH_GUIDE_SECTIONS_SUCCESS = "FETCH_GUIDE_SECTIONS_SUCCESS";
export const FETCH_GUIDE_SECTIONS_FAILED = "FETCH_GUIDE_SECTIONS_FAILED";
export const UPDATE_GUIDE_SECTION = "UPDATE_GUIDE_SECTION";
export const DELETE_GUIDE_SECTION = "DELETE_GUIDE_SECTION";

const fetchSuccess = createAction<Guides.GuidesSection[], typeof FETCH_GUIDE_SECTIONS_SUCCESS>(
    "FETCH_GUIDE_SECTIONS_SUCCESS"
);
const fetchFailed = createAction<void, typeof FETCH_GUIDE_SECTIONS_FAILED>("FETCH_GUIDE_SECTIONS_FAILED");
const updateGuideSetionAction = createAction<Guides.GuidesSection, typeof UPDATE_GUIDE_SECTION>("UPDATE_GUIDE_SECTION");
const deleteGuideSectionAction = createAction<string, typeof DELETE_GUIDE_SECTION>("DELETE_GUIDE_SECTION");

const reducer = createReducer([] as Guides.GuidesSection[], (builder) => {
    builder
        .addCase(fetchSuccess, (state, { payload }) => [...state, ...payload])
        .addCase(fetchFailed, (state) => state)
        .addCase(updateGuideSetionAction, (state, { payload: section }) => {
            const idx = state.findIndex((s) => s.uid === section.uid);

            state[idx] = section;
        })
        .addCase(deleteGuideSectionAction, (state, { payload: sectionId }) => {
            const nextState = customTypedFilter((section) => section.uid !== sectionId, state);

            return nextState;
        })
        .addDefaultCase((state) => state);
});

export default reducer;

export const guideSectionsListenerSaga = function* (): Generator<
    | PutEffect<
          | PayloadAction<Guides.GuidesSection[], typeof FETCH_GUIDE_SECTIONS_SUCCESS>
          | PayloadAction<void, typeof FETCH_GUIDE_SECTIONS_FAILED>
          | PayloadAction<Guides.GuidesSection, typeof UPDATE_GUIDE_SECTION>
          | PayloadAction<string, typeof DELETE_GUIDE_SECTION>
      >
    | CallEffect<EventChannel<(Guides.GuidesSection & { type: "added" | "modified" | "removed" })[]>>
    | TakeEffect,
    void,
    (Guides.GuidesSection & { type: "added" | "modified" | "removed" })[]
> {
    const channel = yield call(sectionsListener);
    try {
        while (true) {
            // @ts-ignore
            const sections = yield take(channel);

            if (!sections.length) {
                yield put(fetchFailed());
            } else if (sections.length === 1 && sections[0].type === "modified") {
                yield put(updateGuideSetionAction(_.omit(sections[0], "type")));
            } else if (sections.length === 1 && sections[0].type === "removed") {
                yield put(deleteGuideSectionAction(sections[0].uid));
            } else {
                yield put(fetchSuccess(_.map(sections, (section) => _.omit(section, "type"))));
            }
        }
    } catch (error) {
        console.log(error);
    }
};

const sectionsListener = () => {
    const ref = collection(firestore, "guideSections");

    return eventChannel<(Guides.GuidesSection & { type: "added" | "modified" | "removed" })[]>((emitter) => {
        const unsubscribe = onSnapshot(
            ref,
            (snapshot) => {
                if (snapshot.empty) {
                    emitter([]);
                }

                let sections: (Guides.GuidesSection & { type: "added" | "modified" | "removed" })[] = [];

                snapshot.docChanges().forEach((doc) => {
                    sections.push({ ...(doc.doc.data() as Guides.GuidesSection), type: doc.type });
                });

                emitter(sections);
            },
            (error) => console.log(error)
        );
        return unsubscribe;
    });
};

export const createGuideSection = async () => {
    const sectionId = uuid();
    const ref = doc(firestore, "guideSections", sectionId);

    try {
        await setDoc(ref, {
            creationDate: new Date(),
            isCore: true,
            order: 0,
            sectionTitle: "Empty Section",
            sectionTitleEn: "Empty Section",
            uid: sectionId,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updateGuideSection = async (sectionId: string, field: string, value: string) => {
    const ref = doc(firestore, "guideSections", sectionId);
    try {
        await updateDoc(ref, { [field]: value });
    } catch (error) {
        console.log(error);
    }
};

export const deleteGuideSection = async (sectionId: string) => {
    const ref = doc(firestore, "guideSections", sectionId);

    try {
        await deleteDoc(ref);
    } catch (error) {
        console.log(error);
    }
};

export const selectGuideSections = createSelector(
    (state: RootState) => state.guideSections,
    (guideSections) => guideSections
);

export const selectGuideSectionsMap = createSelector(
    (state: RootState) => state.guideSections,
    (guideSections) => _.keyBy(guideSections, "uid")
);
