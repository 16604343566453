import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

interface ConfigProps {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
}

const config: ConfigProps = {
    apiKey: 'AIzaSyCGQsQBzJhtQnEtA2DlAaQBOO6X8zwYxZE',
    authDomain: 'connect-web-590a1.firebaseapp.com',
    databaseURL: 'https://connect-web-590a1.firebaseio.com',
    projectId: 'connect-web-590a1',
    storageBucket: 'connect-web-590a1.appspot.com',
    messagingSenderId: '219656075023',
    appId: '219656075023:web:e2674f0b0774f32b0c707a',
};

const app = firebase.initializeApp(config);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const frtdb = getDatabase(app);

export default firebase;
export { auth, firestore, frtdb, functions, storage };
