import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { Guides } from "noex";
import { firestore } from "../../firebase/firebase";
import { createAction, createReducer, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { customTypedFilter } from "../../app/common/util/customFunctions";
import { EventChannel, eventChannel } from "redux-saga";
import { call, PutEffect, take, CallEffect, TakeEffect, put } from "redux-saga/effects";
import _ from "lodash";
import { RootState } from "../rootReducer/rootReducer";

export const SET_GUIDES_LISTENER = "SET_GUIDES_LISTENER";
export const FETCH_GUIDES_SUCCESS = "FETCH_GUIDES_SUCCESS";
export const FETCH_GUIDES_FAILED = "FETCH_GUIDES_FAILED";
export const UPDATE_GUIDE = "UPDATE_GUIDE";
export const DELETE_GUIDE = "DELETE_GUIDE";

const fetchSuccess = createAction<Guides.Guide[], typeof FETCH_GUIDES_SUCCESS>("FETCH_GUIDES_SUCCESS");
const fetchFailed = createAction<void, typeof FETCH_GUIDES_FAILED>("FETCH_GUIDES_FAILED");
const updateGuideAction = createAction<Guides.Guide, typeof UPDATE_GUIDE>("UPDATE_GUIDE");
const deleteGuideAction = createAction<string, typeof DELETE_GUIDE>("DELETE_GUIDE");

const reducer = createReducer([] as Guides.Guide[], (builder) => {
    builder
        .addCase(fetchSuccess, (state, { payload }) => [...state, ...payload])
        .addCase(fetchFailed, (state) => state)
        .addCase(updateGuideAction, (state, { payload: guide }) => {
            const idx = state.findIndex((g) => g.uid === guide.uid);

            state[idx] = guide;
        })
        .addCase(deleteGuideAction, (state, { payload: guideId }) => {
            const nextState = customTypedFilter((guide) => guide.uid !== guideId, state);

            return nextState;
        })
        .addDefaultCase((state) => state);
});

export default reducer;

export const guidesListenerSaga = function* (): Generator<
    | PutEffect<
          | PayloadAction<Guides.Guide[], typeof FETCH_GUIDES_SUCCESS>
          | PayloadAction<void, typeof FETCH_GUIDES_FAILED>
          | PayloadAction<Guides.Guide, typeof UPDATE_GUIDE>
          | PayloadAction<string, typeof DELETE_GUIDE>
      >
    | CallEffect<EventChannel<(Guides.Guide & { type: "added" | "modified" | "removed" })[]>>
    | TakeEffect,
    void,
    (Guides.Guide & { type: "added" | "modified" | "removed" })[]
> {
    const channel = yield call(guidesListener);
    try {
        while (true) {
            // @ts-ignore
            const guides = yield take(channel);

            if (!guides.length) {
                yield put(fetchFailed());
            } else if (guides.length === 1 && guides[0].type === "modified") {
                yield put(updateGuideAction(_.omit(guides[0], "type")));
            } else if (guides.length === 1 && guides[0].type === "removed") {
                yield put(deleteGuideAction(guides[0].uid));
            } else {
                yield put(fetchSuccess(_.map(guides, (guide) => _.omit(guide, "type"))));
            }
        }
    } catch (error) {
        console.log(error);
    }
};

const guidesListener = () => {
    const ref = collection(firestore, "guides");

    return eventChannel<(Guides.Guide & { type: "added" | "modified" | "removed" })[]>((emitter) => {
        const unsubscribe = onSnapshot(
            ref,
            (snapshot) => {
                if (snapshot.empty) {
                    emitter([]);
                }

                let guides: (Guides.Guide & { type: "added" | "modified" | "removed" })[] = [];

                snapshot.docChanges().forEach((doc) => {
                    guides.push({ ...(doc.doc.data() as Guides.Guide), type: doc.type });
                });

                emitter(guides);
            },
            (error) => console.log(error)
        );
        return unsubscribe;
    });
};

export const createGuide = async (guideId: string) => {
    const ref = doc(firestore, "guides", guideId);

    try {
        await setDoc(ref, {
            creationDate: new Date(),
            elements: [],
            isCore: true,
            sectionOrder: 0,
            uid: guideId,
        });
    } catch (error) {
        console.log(error);
    }
};

export const updateGuide = async (data: Partial<Guides.Guide>, guideId: string) => {
    const ref = doc(firestore, "guides", guideId);
    try {
        await updateDoc(ref, { ...data });
    } catch (error) {
        console.log(error);
    }
};

export const deleteGuide = async (guideId: string) => {
    const ref = doc(firestore, "guides", guideId);
    try {
        await deleteDoc(ref);
    } catch (error) {
        console.log(error);
    }
};

export const selectGuides = createSelector(
    (state: RootState) => state.guides,
    (guides) => guides
);
