import { ReactElement } from "react";
import ReactDOM from "react-dom";
import ReduxToastr from "react-redux-toastr";
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { RootState } from "./store/rootReducer/rootReducer";
import store from "./store/configureStore";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import firebase from "./firebase/firebase";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootEl = document.getElementById("app");

// redux-hooks type definition
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const rrfConfig = {
    userProfile: "profiles",
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    updateProfileOnLogin: false,
};

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
};

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <BrowserRouter>
                <>
                    <ToastContainer autoClose={2000} />
                    <ReduxToastr position="bottom-right" transitionIn="fadeIn" transitionOut="fadeOut" />
                    <App />
                </>
            </BrowserRouter>
        </ReactReduxFirebaseProvider>
    </Provider>,
    rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
