import React from 'react';
import styled from 'styled-components';
import { Grid, Hidden, List, ListItemText } from '@material-ui/core';

const Wrapper = styled.div`
    padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
    background: ${(props) => props.theme.palette.common.white};
    position: relative;
`;

const ListItem = styled.div`
    display: inline-block;
    width: auto;
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;

    &,
    &:hover,
    &:active {
        color: #000;
    }
`;

function Footer() {
    return (
        <Wrapper>
            <Grid container spacing={0}>
                <Hidden smDown>
                    <Grid container item xs={12} md={6}>
                        <List style={{ marginLeft: 256, minWidth: 500 }}>
                            <ListItem>
                                <ListItemText primary="Support" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Help Center" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Privacy" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Terms of Service" />
                            </ListItem>
                        </List>
                    </Grid>
                </Hidden>
                <Grid container item xs={12} md={6} justify="flex-end">
                    <List>
                        <ListItem>
                            <ListItemText primary={`© ${new Date().getFullYear()} - NOEX Training Admin v1.0.0`} />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default Footer;
