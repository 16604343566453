import { Dispatch as ReactDispach, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import { Files, Questions } from 'noex';
import { setDoc, doc, collection, getDocs, updateDoc, DocumentReference, DocumentData, deleteDoc, query, orderBy } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { PayloadAction } from '../typedefinitions/typedReduxHooks';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer/rootReducer';
import { Action, AnyAction, Store } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { s3 } from '../../app/common/util/globalVariables';
import { ref as rdbRef, push, remove } from '@firebase/database';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage, frtdb } from '../../firebase/firebase';
import { customTypedFilter } from '../../app/common/util/customFunctions';

export const FETCH_NOEX_POSTS_REQUEST = 'FETCH_NOEX_POSTS_REQUEST';
export const FETCH_NOEX_POSTS_REQUEST_SUCCESS = 'FETCH_NOEX_POSTS_REQUEST_SUCCESS';
export const FETCH_NOEX_POSTS_REQUEST_FAILED = 'FETCH_NOEX_POSTS_REQUEST_FAILED';
export const CREATE_NOEX_POST = 'CREATE_NOEX_POST';
export const UPDATE_NOEX_POST = 'UPDATE_NOEX_POST';
export const POST_PUBLISH_STATE_CHANGE = 'POST_PUBLISH_STATE_CHANGE';

const initialState: (Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> })[] =
    [];

export default function noexPostsReducer(
    state = initialState,
    {
        payload,
        type,
    }: PayloadAction<
        typeof initialState,
        | typeof FETCH_NOEX_POSTS_REQUEST
        | typeof FETCH_NOEX_POSTS_REQUEST_SUCCESS
        | typeof FETCH_NOEX_POSTS_REQUEST_FAILED
        | typeof CREATE_NOEX_POST
        | typeof UPDATE_NOEX_POST
        | typeof POST_PUBLISH_STATE_CHANGE
    >,
) {
    switch (type) {
        case 'FETCH_NOEX_POSTS_REQUEST':
            return state;
        case 'FETCH_NOEX_POSTS_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_NOEX_POSTS_REQUEST_FAILED':
            return state;
        case 'CREATE_NOEX_POST':
            return payload;
        case 'UPDATE_NOEX_POST':
            return payload;
        case 'POST_PUBLISH_STATE_CHANGE':
            return payload;
        default:
            return state;
    }
}

export const fetchNOEXPosts =
    (): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_NOEX_POSTS_REQUEST_SUCCESS>
        | Action<typeof FETCH_NOEX_POSTS_REQUEST | typeof FETCH_NOEX_POSTS_REQUEST_FAILED>
    > =>
    async (dispatch) => {
        dispatch({ type: 'FETCH_NOEX_POSTS_REQUEST' });
        const ref = query(collection(firestore, 'posts'), orderBy('creationDate', 'desc'));
        try {
            const query = await getDocs(ref);

            if (query.empty) {
                dispatch({ type: 'FETCH_NOEX_POSTS_REQUEST_FAILED' });
                return;
            }

            let posts: (Questions.Post & { ref: DocumentReference<DocumentData> })[] = [];

            for (let index = 0; index < query.docs.length; index++) {
                const post = query.docs?.[index];

                posts.push({ ...post.data(), ref: post.ref } as Questions.Post & { ref: DocumentReference<DocumentData> });
            }

            dispatch({ type: 'FETCH_NOEX_POSTS_REQUEST_SUCCESS', payload: posts });

            return;
        } catch (error) {
            console.log(error);
        }
    };

export const createNOEXPost =
    (
        post: string,
        teamIds: string[],
        userName: string,
        userProfilePicture: string,
        userId: string,
        sharedMedia?: Files.Asset[],
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof CREATE_NOEX_POST>> =>
    async (dispatch, getState) => {
        const postId = uuid();
        const ref = doc(firestore, 'posts', postId);

        const newPost: Questions.Post = {
            // @ts-ignore
            creationDate: new Date(),
            isCore: true,
            isDev: false,
            post,
            sharedMedia,
            teamUids: teamIds,
            uid: postId,
            userName: userName,
            userProfilePicture,
            userUid: userId,
        };

        try {
            await setDoc(ref, _(newPost).omitBy(_.isNil).value());

            const prevState = getState().noexPosts;
            const nextState = [{ ...newPost, ref }, ...prevState];

            dispatch({ type: 'CREATE_NOEX_POST', payload: nextState });
        } catch (error) {
            console.log(error);
        }
    };

export const updateNOEXPost =
    (
        postId: string,
        data: { post?: string; teamUids?: string[]; sharedMedia?: Files.Asset[] },
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof UPDATE_NOEX_POST>> =>
    async (dispatch, getState) => {
        const ref = doc(firestore, 'posts', postId);

        try {
            await updateDoc(ref, { ...data });

            const prevState = getState().noexPosts;
            const updatedPostIndex = prevState.findIndex((post) => postId === post.uid);
            let nextState: (Questions.Post & {
                comments?: Questions.Comment[] & { replies?: Questions.Reply[] };
                ref: DocumentReference<DocumentData>;
            })[] = [];

            for (let index = 0; index < prevState.length; index++) {
                const p = prevState?.[index];

                if (updatedPostIndex === index) {
                    nextState.push({ ...p, ...data });
                } else {
                    nextState.push(p);
                }
            }

            dispatch({ type: 'UPDATE_NOEX_POST', payload: nextState });
        } catch (error) {
            console.log(error);
        }
    };

export const deleteNOEXPost =
    (
        postId: string,
        postIndex: number,
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof UPDATE_NOEX_POST>> =>
    async (dispatch, getState) => {
        const ref = doc(firestore, 'posts', postId);

        try {
            await deleteDoc(ref);

            const prevState = getState().noexPosts;
            let nextState: (Questions.Post & {
                comments?: Questions.Comment[] & { replies?: Questions.Reply[] };
                ref: DocumentReference<DocumentData>;
            })[] = [];

            for (let index = 0; index < prevState.length; index++) {
                const post = prevState?.[index];

                if (post?.sharedMedia && index === postIndex) {
                    for (let index = 0; index < post.sharedMedia!.length; index++) {
                        const file = post.sharedMedia?.[index];

                        // TODO: typecheck
                        // @ts-ignore
                        await deleteFile(file!, post.uid);
                    }
                }

                if (index !== postIndex) {
                    nextState.push(post);
                }
            }

            dispatch({ type: 'UPDATE_NOEX_POST', payload: nextState });
        } catch (error) {
            console.log(error);
        }
    };

export const publishNOEXPost =
    (
        postId: string,
        postIndex: number,
        state: boolean,
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof POST_PUBLISH_STATE_CHANGE>> =>
    async (dispatch, getState) => {
        const ref = doc(firestore, 'posts', postId);
        try {
            await updateDoc(ref, { isDev: state });

            const prevState: typeof initialState = getState().noexPosts;
            let nextState: typeof initialState = [];

            for (let index = 0; index < prevState.length; index++) {
                const post = prevState?.[index];

                if (index === postIndex) {
                    nextState.push({ ...post, isDev: state });
                } else {
                    nextState.push({ ...post });
                }
            }

            dispatch({ type: 'POST_PUBLISH_STATE_CHANGE', payload: nextState });
        } catch (error) {
            console.log(error);
        }
    };

export const uploader =
    (
        dispatch: ThunkDispatch<RootState, undefined, PayloadAction<Files.Asset[], typeof UPDATE_NOEX_POST>>,
        getState: Store<RootState, AnyAction>['getState'],
        field: string,
        updateRef: DocumentReference<DocumentData>,
        setProgress?: ReactDispach<SetStateAction<number>>,
    ) =>
    async (files: File[]) => {
        let uploadReturns: Files.Asset[] = [];

        for (let index = 0; index < files.length; index++) {
            const file = files?.[index];
            const storageRef = ref(storage, updateRef.path + '/' + file.name);
            // firebase storage
            const uploadReturn = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(uploadReturn.ref);

            const response = await push(rdbRef(frtdb, updateRef.path), {
                ..._({ ...uploadReturn.metadata, name: file.name, fullPath: uploadReturn.metadata + '/' + file.name })
                    .omitBy(_.isNil)
                    ?.value(),
            });

            // DO Spaces
            const uploadTask = s3.upload(
                {
                    Bucket: 'noex-training',
                    Key: `${updateRef.path}/${file.name}`,
                    Body: file,
                    ACL: 'public-read',
                },
                (error, data) => {
                    if (error) {
                        console.log(error, error.stack);
                    } else {
                        console.log(data);
                    }
                },
            );
            uploadTask.on('httpUploadProgress', (progress) => setProgress!(parseFloat(((progress.loaded / progress.total) * 100).toFixed(0))));
            const doResponse = await uploadTask.promise().then((res) => res);

            uploadReturns.push({
                downloadURL,
                fileData: { ...(_(uploadReturn.metadata).omitBy(_.isNil)?.value() as unknown as Files.Asset['fileData']) },
                key: response.key!,
                // @ts-ignore
                mirror: { ...doResponse },
            });
        }
        const post = customTypedFilter((post) => post.uid === updateRef.id, getState().noexPosts);

        // TODO: need to refractor for general case
        dispatch(
            updateNOEXPost(updateRef.id, { [field]: post?.[0]?.sharedMedia ? [...post?.[0]?.sharedMedia!, ...uploadReturns] : [...uploadReturns] }),
        );
    };

export const deleteFile = async (file: Files.Asset, postId: string) => {
    try {
        // remove from S3
        s3.deleteObject(
            {
                Bucket: file.mirror?.Bucket!,
                Key: file.mirror?.Key!,
            },
            (error, data) => {
                if (error) {
                    console.log(error, error.stack);
                } else {
                    console.log(data);
                }
            },
        );

        // remove from real-time db
        await remove(rdbRef(frtdb, `posts/${postId}` + '/' + file.key));

        // remove from firebase storage
        await deleteObject(ref(storage, file.fileData.fullPath));
    } catch (error) {
        console.log(error);
    }
};

// selectors
export const selectNOEXPosts = createSelector(
    (state: RootState) => state.noexPosts,
    (posts) => posts,
);
