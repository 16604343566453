import _ from "lodash";

interface WelcomeProps {}

function Welcome({}: WelcomeProps) {
    return (
        <div>
            {/* // <button onClick={() => remove()}>GetData</button> */}
            <h1>Welcome on board!</h1>
            <h3>join to the Connect Fitness trainer platform.</h3>
        </div>
    );
}

export default Welcome;
