function createShadow(px: Number) {
    return `0 0 ${px}px 0 rgba(53,64,82,.05)`;
}

const shadows: any = [
    'none',
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
    createShadow(0),
];

export default shadows;
