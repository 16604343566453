import React, { ReactElement, useState } from 'react';
import { Grid, Hidden, Menu, MenuItem, AppBar as MuiAppBar, IconButton as MuiIconButton, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Power } from 'react-feather';
import styled from 'styled-components';
import { FirebaseFirestore } from '../../app/common/util/interfaces/intefaces_global';

const AppBar = styled(MuiAppBar)`
    background: ${(props) => props.theme.header.background};
    color: ${(props) => props.theme.header.color};
    box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

function UserMenu({ history, firebase }: { history: any; firebase: FirebaseFirestore['firebase'] }) {
    const [anchorMenu, setAnchorMenu] = useState(null);

    const toggleMenu = (event: any) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = () => {
        firebase.logout();
        history.push('/');
    };

    return (
        <React.Fragment>
            {/*<Button component={Link} to="/workouts/create-workout/" variant="outlined" color="primary" style={{ marginLeft: 8 }}>
                Create Workout
            </Button>
            <Button component={Link} to="/workouts/create-team-workout/" variant="outlined" color="primary" style={{ marginLeft: 8 }}>
                Create Team Workout
            </Button>
            <Button component={Link} to="/programs/create-program/create-private-program/" variant="outlined" color="primary" style={{ marginLeft: 8 }}>
                Create Program
            </Button>
            <Button component={Link} to="/exercises/add-exercise/" variant="outlined" color="primary" style={{ marginLeft: 8 }}>
                Add Exercise
            </Button>*/}
            <IconButton aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit">
                <Power />
            </IconButton>
            <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
                <MenuItem onClick={() => handleSignOut()}>Sign out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

function Header({ onDrawerToggle, firebase, history }: any): ReactElement {
    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Hidden mdUp>
                            <Grid item>
                                <IconButton color="inherit" aria-label="Open drawer" onClick={onDrawerToggle}>
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        {/*<Grid item>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <Input placeholder="Search topics" />
                        </Search>
                    </Grid>*/}
                        <Grid item xs />
                        <Grid item>
                            <UserMenu history={history} firebase={firebase} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}

export default Header;
