import { PayloadAction } from '../typedefinitions/typedReduxHooks';
import { Questions } from 'noex';
import {
    query,
    collectionGroup,
    orderBy,
    getDocs,
    startAfter,
    limit,
    DocumentData,
    QueryDocumentSnapshot,
    DocumentReference,
    collection,
} from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../rootReducer/rootReducer';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { Dispatch, SetStateAction } from 'react';

export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_REQUEST_SUCCESS = 'FETCH_POSTS_REQUEST_SUCCESS';
export const FETCH_POSTS_REQUEST_FAILED = 'FETCH_POSTS_REQUEST_FAILED';
export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_REQUEST_SUCCESS = 'FETCH_COMMENTS_REQUEST_SUCCESS';
export const FETCH_COMMENTS_REQUEST_FAILED = 'FETCH_COMMENTS_REQUEST_FAILED';
export const FETCH_REPLIES_REQUEST = 'FETCH_REPLIES_REQUEST';
export const FETCH_REPLIES_REQUEST_SUCCESS = 'FETCH_REPLIES_REQUEST_SUCCESS';
export const FETCH_REPLIES_REQUEST_FAILED = 'FETCH_REPLIES_REQUEST_FAILED';

const initialState: {
    data: Record<string, Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> }>;
    ordered: (Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> })[];
} = {
    data: {},
    ordered: [],
};

export default function feedReducer(
    state = initialState,
    {
        payload,
        type,
    }: PayloadAction<
        typeof initialState,
        | typeof FETCH_POSTS_REQUEST
        | typeof FETCH_POSTS_REQUEST_SUCCESS
        | typeof FETCH_POSTS_REQUEST_FAILED
        | typeof FETCH_COMMENTS_REQUEST
        | typeof FETCH_COMMENTS_REQUEST_SUCCESS
        | typeof FETCH_COMMENTS_REQUEST_FAILED
        | typeof FETCH_REPLIES_REQUEST
        | typeof FETCH_REPLIES_REQUEST_SUCCESS
        | typeof FETCH_REPLIES_REQUEST_FAILED
    >,
) {
    switch (type) {
        case 'FETCH_POSTS_REQUEST':
            return state;
        case 'FETCH_POSTS_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_POSTS_REQUEST_FAILED':
            return state;
        case 'FETCH_COMMENTS_REQUEST':
            return state;
        case 'FETCH_COMMENTS_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_COMMENTS_REQUEST_FAILED':
            return state;
        case 'FETCH_REPLIES_REQUEST':
            return state;
        case 'FETCH_REPLIES_REQUEST_SUCCESS':
            return state;
        case 'FETCH_REPLIES_REQUEST_FAILED':
            return state;
        default:
            return state;
    }
}

export const fetchPosts =
    (
        setLatest: Dispatch<SetStateAction<QueryDocumentSnapshot<DocumentData>>>,
    ): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_POSTS_REQUEST_SUCCESS>
        | Action<typeof FETCH_POSTS_REQUEST | typeof FETCH_POSTS_REQUEST_FAILED>
    > =>
    async (dispatch, getState) => {
        dispatch({ type: 'FETCH_POSTS_REQUEST' });
        const ref = query(collectionGroup(firestore, 'questions'), orderBy('creationDate', 'desc'), limit(5));

        try {
            const query = await getDocs(ref);

            if (query.empty) {
                dispatch({ type: 'FETCH_POSTS_REQUEST_FAILED' });

                return;
            }

            let data: Record<
                string,
                Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> }
            > = {};
            let ordered: (Questions.Post & {
                comments?: Questions.Comment[] & { replies?: Questions.Reply[] };
                ref: DocumentReference<DocumentData>;
            })[] = [];

            for (let index = 0; index < query.docs.length; index++) {
                const post = query.docs?.[index];

                data[post.id] = { ...(post.data() as Questions.Post), ref: post.ref };
                ordered.push({ ...(post.data() as Questions.Post), ref: post.ref });
            }

            const prevState = getState().feed;
            const nextState = {
                data: {
                    ...prevState.data,
                    ...data,
                },
                ordered: [...prevState.ordered, ...ordered],
            };

            dispatch({ type: 'FETCH_POSTS_REQUEST_SUCCESS', payload: nextState });
            setLatest(query.docs[query.docs.length - 1]);

            return;
        } catch (error) {
            console.log(error);
        }
    };

export const getMorePosts =
    (
        latest: QueryDocumentSnapshot<DocumentData>,
        setLatest: Dispatch<SetStateAction<QueryDocumentSnapshot<DocumentData>>>,
    ): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_POSTS_REQUEST_SUCCESS>
        | Action<typeof FETCH_POSTS_REQUEST | typeof FETCH_POSTS_REQUEST_FAILED>
    > =>
    async (dispatch, getState) => {
        dispatch({ type: 'FETCH_POSTS_REQUEST' });
        const ref = query(collectionGroup(firestore, 'questions'), orderBy('creationDate', 'desc'), startAfter(latest), limit(5));

        try {
            const query = await getDocs(ref);

            if (query.empty) {
                dispatch({ type: 'FETCH_POSTS_REQUEST_FAILED' });

                return;
            }

            let data: Record<
                string,
                Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> }
            > = {};
            let ordered: (Questions.Post & {
                comments?: Questions.Comment[] & { replies?: Questions.Reply[] };
                ref: DocumentReference<DocumentData>;
            })[] = [];

            for (let index = 0; index < query.docs.length; index++) {
                const post = query.docs?.[index];

                data[post.id] = { ...(post.data() as Questions.Post), ref: post.ref };
                ordered.push({ ...(post.data() as Questions.Post), ref: post.ref });
            }

            const prevState = getState().feed;
            const nextState = {
                data: {
                    ...prevState.data,
                    ...data,
                },
                ordered: [...prevState.ordered, ...ordered],
            };

            dispatch({ type: 'FETCH_POSTS_REQUEST_SUCCESS', payload: nextState });
            setLatest(query.docs[query.docs.length - 1]);

            return;
        } catch (error) {
            console.log(error);
        }
    };

export const fetchCommentsAndReplies =
    (
        postId: string,
        postIndex: number,
        postRef: DocumentReference,
    ): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_COMMENTS_REQUEST_SUCCESS>
        | Action<
              | typeof FETCH_COMMENTS_REQUEST
              | typeof FETCH_COMMENTS_REQUEST_FAILED
              | typeof FETCH_REPLIES_REQUEST
              | typeof FETCH_REPLIES_REQUEST_FAILED
              | typeof FETCH_REPLIES_REQUEST_SUCCESS
          >
    > =>
    async (dispatch, getState) => {
        dispatch({ type: 'FETCH_COMMENTS_REQUEST' });
        const cref = query(collection(firestore, postRef.path, 'comments'), orderBy('creationDate', 'asc'));
        try {
            const cquery = await getDocs(cref);

            if (cquery.empty) {
                dispatch({ type: 'FETCH_COMMENTS_REQUEST_FAILED' });

                return;
            }

            let comments = [] as (Questions.Comment & { replies?: Questions.Reply[] })[];

            for (let index = 0; index < cquery.docs.length; index++) {
                const comment = cquery.docs?.[index];

                dispatch({ type: 'FETCH_REPLIES_REQUEST' });
                const rref = query(collection(firestore, comment.ref.path, 'replys'), orderBy('creationDate', 'asc'));
                const rquery = await getDocs(rref);

                if (rquery.empty) {
                    dispatch({ type: 'FETCH_REPLIES_REQUEST_FAILED' });
                    comments.push({ ...(comment.data() as Questions.Comment) });

                    continue;
                }

                let replies = [] as Questions.Reply[];

                for (let index = 0; index < rquery.docs.length; index++) {
                    const reply = rquery.docs?.[index];

                    replies.push(reply.data() as Questions.Reply);
                }

                dispatch({ type: 'FETCH_REPLIES_REQUEST_SUCCESS' });

                comments.push({ ...(comment.data() as Questions.Comment), replies });
            }

            const prevStateData = getState().feed.data;
            const prevStateOrdered = getState().feed.ordered;

            let nextStateData: Record<
                string,
                Questions.Post & { comments?: Questions.Comment[] & { replies?: Questions.Reply[] }; ref: DocumentReference<DocumentData> }
            > = {};
            let nextStateOrdered: (Questions.Post & {
                comments?: Questions.Comment[] & { replies?: Questions.Reply[] };
                ref: DocumentReference<DocumentData>;
            })[] = [];

            for (const key in prevStateData) {
                if (key === postId) {
                    nextStateData[key] = { ...prevStateData?.[key], comments };
                } else {
                    nextStateData[key] = { ...prevStateData?.[key] };
                }
            }

            for (let index = 0; index < prevStateOrdered.length; index++) {
                const post = prevStateOrdered?.[index];

                if (postIndex === index) {
                    nextStateOrdered.push({ ...post, comments });
                } else {
                    nextStateOrdered.push({ ...post });
                }
            }

            const nextState = {
                data: nextStateData,
                ordered: nextStateOrdered,
            };

            dispatch({ type: 'FETCH_COMMENTS_REQUEST_SUCCESS', payload: nextState });
        } catch (error) {
            console.log(error);
        }
    };

// selectors
export const selectOrderedPosts = createSelector(
    (state: RootState) => state.feed.ordered,
    (feed) => feed,
);

export const selectPostById = (postId: string) =>
    createSelector(
        (state: RootState) => state.feed.data[postId],
        (post) => post,
    );
