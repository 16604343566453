import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";
import { reducer as ToastrReducer } from "react-redux-toastr";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import guides from "../v2/guides";
import guideSections from "../v2/guideSections";
import themeReducer from "../reducers/theme";
import feedReducer from "../v2/feed";
import noexPostsReducer from "../v2/noexPosts";
import usersReducer from "../v2/users";
import trainersFilesReducer from "../v2/trainersFiles";
import programsReducer from "../v2/programs";

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    feed: feedReducer,
    form: FormReducer,
    guides,
    guideSections,
    noexPosts: noexPostsReducer,
    programs: programsReducer,
    theme: themeReducer,
    toastr: ToastrReducer,
    trainersFiles: trainersFilesReducer,
    users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
