import { arrayRemove, arrayUnion, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import produce from "immer";
import _ from "lodash";
import { Programs } from "noex";
import { END, EventChannel, eventChannel } from "redux-saga";
import { call, CallEffect, cancelled, CancelledEffect, put, PutEffect, take, TakeEffect } from "redux-saga/effects";
import { createSelector } from "reselect";
import { customTypedFilter } from "../../app/common/util/customFunctions";
import { firestore } from "../../firebase/firebase";
import { RootState } from "../rootReducer/rootReducer";
import { PayloadAction } from "../typedefinitions/typedReduxHooks";

export const SET_PROGRAMS_LISTENER = "SET_PROGRAMS_LISTENER";
export const SET_PROGRAMS_LISTENER_RESPONSE = "SET_PROGRAMS_LISTENER_RESPONSE";
export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";

const initialState: Programs.Program[] = [];

export default function programsReducer(
    state = initialState,
    {
        payload,
        type,
    }:
        | PayloadAction<typeof initialState, typeof SET_PROGRAMS_LISTENER | typeof SET_PROGRAMS_LISTENER_RESPONSE>
        | PayloadAction<Programs.Program, typeof DELETE_PROGRAM | typeof UPDATE_PROGRAM>
) {
    switch (type) {
        case "SET_PROGRAMS_LISTENER":
            return state;
        case "SET_PROGRAMS_LISTENER_RESPONSE":
            return _.orderBy([...state, ...(payload as Programs.Program[])], "title");
        case "UPDATE_PROGRAM":
            return produce(state, (draftState) => {
                // TODO: need to check the typescript settings because VS Code can see the right typing
                const updatedProgramIndex = state.findIndex(
                    (program) => program.uid === (payload as Programs.Program).uid
                );
                draftState[updatedProgramIndex] = payload as Programs.Program;

                return draftState;
            });
        case "DELETE_PROGRAM":
            return produce(state, (_draftState) => {
                const nextState = customTypedFilter(
                    (program) => program.uid !== (payload as Programs.Program).uid,
                    state
                );

                return nextState;
            });
        default:
            return state;
    }
}

export const programsListenerSaga = function* (): Generator<
    | PutEffect<
          | PayloadAction<Programs.Program[], typeof SET_PROGRAMS_LISTENER_RESPONSE>
          | PayloadAction<Programs.Program, typeof UPDATE_PROGRAM>
          | PayloadAction<Programs.Program, typeof DELETE_PROGRAM>
      >
    | CallEffect<
          EventChannel<{
              actionType: typeof SET_PROGRAMS_LISTENER_RESPONSE | typeof UPDATE_PROGRAM | typeof DELETE_PROGRAM;
              programs: Programs.Program[];
          }>
      >
    | TakeEffect
    | CancelledEffect,
    void,
    {
        actionType: typeof SET_PROGRAMS_LISTENER_RESPONSE | typeof UPDATE_PROGRAM | typeof DELETE_PROGRAM;
        programs: Programs.Program[];
    }
> {
    const channel = yield call(programsListener);

    try {
        while (true) {
            // @ts-ignore
            const payload = yield take(channel);

            if (payload.actionType === "SET_PROGRAMS_LISTENER_RESPONSE") {
                yield put({ type: "SET_PROGRAMS_LISTENER_RESPONSE", payload: payload.programs });
            } else if (payload.actionType === "UPDATE_PROGRAM") {
                yield put({ type: "UPDATE_PROGRAM", payload: payload.programs?.[0] });
            } else {
                yield put({ type: "DELETE_PROGRAM", payload: payload.programs?.[0] });
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        if (yield cancelled()) {
            // @ts-ignore
            channel.close();
        }
    }
};

export const programsListener = () => {
    const ref = collection(firestore, "programs");

    return eventChannel<{
        actionType: typeof SET_PROGRAMS_LISTENER_RESPONSE | typeof UPDATE_PROGRAM | typeof DELETE_PROGRAM;
        programs: Programs.Program[];
    }>((emitter) => {
        const unsubscribe = onSnapshot(
            ref,
            (snapshot) => {
                if (snapshot.empty) {
                    emitter(END);
                }

                let programs: Programs.Program[] = [];
                let actionType!: typeof SET_PROGRAMS_LISTENER_RESPONSE | typeof UPDATE_PROGRAM | typeof DELETE_PROGRAM;

                snapshot.docChanges().forEach((doc) => {
                    programs.push(doc.doc.data() as Programs.Program);

                    if (doc.type === "added") {
                        actionType = SET_PROGRAMS_LISTENER_RESPONSE;
                    } else if (doc.type === "modified") {
                        actionType = UPDATE_PROGRAM;
                    } else {
                        actionType = DELETE_PROGRAM;
                    }
                });

                emitter({ actionType, programs });
            },
            (error) => console.log(error)
        );

        return unsubscribe;
    });
};

export const activeProgramInTeam = async (state: "activate" | "inactivate", programId: string, teamId: string) => {
    const ref = doc(firestore, "programs", programId);

    try {
        if (state === "activate") {
            await updateDoc(ref, {
                teamUids: arrayUnion(teamId),
            });
        } else {
            await updateDoc(ref, {
                teamUids: arrayRemove(teamId),
            });
        }
    } catch (error) {
        console.log(error);
    }
};

// selectors
export const selectPrograms = createSelector(
    (state: RootState) => state.programs,
    (programs) => programs
);
