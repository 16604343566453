import { all, takeLatest } from "redux-saga/effects";
import { guidesListenerSaga, SET_GUIDES_LISTENER } from "./guides";
import { guideSectionsListenerSaga, SET_GUIDE_SECTIONS_LISTENER } from "./guideSections";
import { programsListenerSaga, SET_PROGRAMS_LISTENER } from "./programs";

export default function* rootSaga() {
    yield all([
        takeLatest(SET_GUIDE_SECTIONS_LISTENER, guideSectionsListenerSaga),
        takeLatest(SET_GUIDES_LISTENER, guidesListenerSaga),
        takeLatest(SET_PROGRAMS_LISTENER, programsListenerSaga),
    ]);
}
