import { Dispatch, SetStateAction } from 'react';
import { query, collection, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { Users } from 'noex';
import { ThunkAction } from 'redux-thunk';
import { firestore, functions } from '../../firebase/firebase';
import { PayloadAction } from '../typedefinitions/typedReduxHooks';
import { RootState } from '../rootReducer/rootReducer';
import { Action } from 'redux';
import { createSelector } from 'reselect';
import { httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { customTypedFilter } from '../../app/common/util/customFunctions';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_REQUEST_SUCCESS = 'FETCH_USERS_REQUEST_SUCCESS';
export const FETCH_USERS_REQUEST_FAILED = 'FETCH_USERS_REQUEST_FAILED';
export const FETCH_SUPPORT_USERS_REQUEST = 'FETCH_SUPPORT_USERS_REQUEST';
export const FETCH_SUPPORT_USERS_REQUEST_SUCCESS = 'FETCH_SUPPORT_USERS_REQUEST_SUCCESS';
export const FETCH_SUPPORT_USERS_REQUEST_FAILED = 'FETCH_SUPPORT_USERS_REQUEST_FAILED';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';

const initialState: {
    data: Record<string, Users.User & { id: string }>;
    ordered: (Users.User & { id: string })[];
} = {
    data: {},
    ordered: [],
};

export default function usersReducer(
    state = initialState,
    {
        payload,
        type,
    }: PayloadAction<
        typeof initialState,
        | typeof FETCH_USERS_REQUEST
        | typeof FETCH_USERS_REQUEST_SUCCESS
        | typeof FETCH_USERS_REQUEST_FAILED
        | typeof FETCH_SUPPORT_USERS_REQUEST
        | typeof FETCH_SUPPORT_USERS_REQUEST_SUCCESS
        | typeof FETCH_SUPPORT_USERS_REQUEST_FAILED
        | typeof CREATE_USER
        | typeof CREATE_USER_FAILED
        | typeof UPDATE_USER
        | typeof DELETE_USER
    >,
) {
    switch (type) {
        case 'FETCH_USERS_REQUEST':
            return state;
        case 'FETCH_USERS_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_USERS_REQUEST_FAILED':
            return state;
        case 'FETCH_SUPPORT_USERS_REQUEST':
            return state;
        case 'FETCH_SUPPORT_USERS_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_SUPPORT_USERS_REQUEST_FAILED':
            return state;
        case 'CREATE_USER':
            return payload;
        case 'CREATE_USER_FAILED':
            return state;
        case 'UPDATE_USER':
            return payload;
        case 'DELETE_USER':
            return payload;
        default:
            return state;
    }
}

export const fetchUsers =
    (): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_USERS_REQUEST_SUCCESS>
        | Action<typeof FETCH_USERS_REQUEST | typeof FETCH_USERS_REQUEST_FAILED>
    > =>
    async (dispatch, getState) => {
        dispatch({ type: 'FETCH_USERS_REQUEST' });
        const vipsRef = query(collection(firestore, 'profiles'), where('isVip', '==', true));
        const iapRef = query(collection(firestore, 'profiles'), where('inAppPurchase.isActive', '==', true));
        const stripeRef = query(collection(firestore, 'profiles'), where('stripeSubscription01.isActive', '==', true));
        const challengeRef = query(collection(firestore, 'profiles'), where('stripeChallenge.isActive', '==', true));
        try {
            const query = await Promise.all([getDocs(vipsRef), getDocs(iapRef), getDocs(stripeRef), getDocs(challengeRef)]);
            const queryResEmptyCheck = query.map((item) => item.empty).filter((item) => item);
            if (queryResEmptyCheck.length === query.length) {
                dispatch({ type: 'FETCH_USERS_REQUEST_FAILED' });

                return;
            }

            let data: Record<string, Users.User & { id: string }> = {};
            let ordered: (Users.User & { id: string })[] = [];

            for (let index = 0; index < query.length; index++) {
                const queryElement = query?.[index];

                for (let index = 0; index < queryElement.docs.length; index++) {
                    const user = queryElement.docs?.[index];

                    data[user.id] = { ...(user.data() as Users.User), id: user.id };
                    ordered.push({ ...(user.data() as Users.User), id: user.id });
                }
            }

            const prevStateOrdered = getState().users.ordered;

            dispatch({ type: 'FETCH_USERS_REQUEST_SUCCESS', payload: { data, ordered: _.uniqBy([...ordered, ...prevStateOrdered], 'uid') } });

            return;
        } catch (error) {
            console.log(error);
        }
    };

export const fetchSupportUsers =
    (): ThunkAction<
        Promise<void>,
        RootState,
        undefined,
        | PayloadAction<typeof initialState, typeof FETCH_SUPPORT_USERS_REQUEST_SUCCESS>
        | Action<typeof FETCH_SUPPORT_USERS_REQUEST | typeof FETCH_SUPPORT_USERS_REQUEST_FAILED>
    > =>
    async (dispatch) => {
        dispatch({ type: 'FETCH_SUPPORT_USERS_REQUEST' });
        const ref = query(collection(firestore, 'profiles'), where('isSupport', '==', true));
        try {
            const query = await getDocs(ref);

            if (query.empty) {
                dispatch({ type: 'FETCH_SUPPORT_USERS_REQUEST_FAILED' });

                return;
            }

            let data: Record<string, Users.User & { id: string }> = {};
            let ordered: (Users.User & { id: string })[] = [];

            for (let index = 0; index < query.docs.length; index++) {
                const user = query.docs?.[index];

                data[user.id] = { ...(user.data() as Users.User), id: user.id };
                ordered.push({ ...(user.data() as Users.User), id: user.id });
            }

            dispatch({ type: 'FETCH_SUPPORT_USERS_REQUEST_SUCCESS', payload: { data, ordered } });

            return;
        } catch (error) {
            console.log(error);
        }
    };

export const createSupportUser =
    (
        name: string,
        email: string,
        password: string,
        setLoader?: Dispatch<SetStateAction<boolean>>,
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof CREATE_USER> | Action<typeof CREATE_USER_FAILED>> =>
    async (dispatch, getState) => {
        if (setLoader) {
            setLoader((prevState) => !prevState);
        }
        const createSupportUser = httpsCallable(functions, 'createSupportUser');

        try {
            const user = (await createSupportUser({ displayName: name, email, password })) as any; // Firebase admin.Auth.UserRecord;
            const ref = doc(firestore, 'profiles', user?.data?.uid);
            const query = await getDoc(ref);

            if (!query.exists()) {
                dispatch({ type: 'CREATE_USER_FAILED' });

                return;
            }

            const prevStateData = getState().users.data;
            const prevStateOrdered = getState().users.ordered;

            dispatch({
                type: 'CREATE_USER',
                payload: {
                    data: { ...prevStateData, [user.uid]: { ...(query.data() as Users.User), id: query.id } },
                    ordered: [...prevStateOrdered, { ...(query.data() as Users.User), id: query.id }],
                },
            });

            if (setLoader) {
                setLoader((prevState) => !prevState);
            }
        } catch (error) {
            console.log(error);
        }
    };

export const createTrainer = () => async () => {};

export const updateUser = () => async () => {};

export const deleteUser =
    (
        userId: string,
        setLoader?: Dispatch<SetStateAction<boolean>>,
    ): ThunkAction<Promise<void>, RootState, undefined, PayloadAction<typeof initialState, typeof DELETE_USER>> =>
    async (dispatch, getState) => {
        if (setLoader) {
            setLoader((prevState) => !prevState);
        }
        const deleteUser = httpsCallable(functions, 'deleteUser');

        try {
            await deleteUser({ path: `profiles/${userId}`, uid: userId });

            const prevStateData = getState().users.data;
            const prevStateOrdered = getState().users.ordered;
            const userIndex = prevStateOrdered?.findIndex((user) => user.uid === userId);

            let data: Record<string, Users.User & { id: string }> = {};
            let ordered: (Users.User & { id: string })[] = [];

            for (const key in prevStateData) {
                const user = prevStateData?.[key];

                if (key !== user?.uid) {
                    data[key] = { ...user };
                }
            }

            for (let index = 0; index < prevStateOrdered.length; index++) {
                const user = prevStateOrdered?.[index];

                if (index !== userIndex) {
                    ordered.push(user);
                }
            }

            dispatch({ type: 'DELETE_USER', payload: { data, ordered } });

            if (setLoader) {
                setLoader((prevState) => !prevState);
            }

            toast('User deletion success!', { position: toast.POSITION.BOTTOM_RIGHT });
        } catch (error) {
            console.log(error);
        }
    };

// selectors
export const selectOrderedUsers = createSelector(
    (state: RootState) => state.users.ordered,
    (users) => users,
);

export const selectSupportUsers = createSelector(
    (state: RootState) => state.users.ordered,
    (users) => customTypedFilter((user) => user.isSupport!, users),
);

export const selectDataUsers = createSelector(
    (state: RootState) => state.users.data,
    (users) => users,
);

export const selectUserById = (userId: string) =>
    createSelector(
        (state: RootState) => state.users.data,
        (users) => users?.[userId],
    );
