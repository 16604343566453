import { put, PutEffect } from '@redux-saga/core/effects';
import { collectionGroup, getDocs, DocumentData, QuerySnapshot, doc, updateDoc } from 'firebase/firestore';
import { Files } from 'noex';
import { Action } from 'redux';
import { firestore } from '../../firebase/firebase';
import { PayloadAction } from '../typedefinitions/typedReduxHooks';
import { produce } from 'immer';
import { createSelector } from 'reselect';
import { RootState } from '../rootReducer/rootReducer';
import { customTypedFilter } from '../../app/common/util/customFunctions';

export const FETCH_TRAINERS_FILES_REQUEST = 'FETCH_TRAINERS_FILES_REQUEST';
export const FETCH_TRAINERS_FILES_REQUEST_SUCCESS = 'FETCH_TRAINERS_FILES_REQUEST_SUCCESS';
export const FETCH_TRAINERS_FILES_REQUEST_FAILED = 'FETCH_TRAINERS_FILES_REQUEST_FAILED';
export const APPROVE_TRAINER_FILE = 'APPROVE_TRAINER_FILE';
export const DENIED_TRAINER_FILE = 'DENIED_TRAINER_FILE';

const initialState: Files.TrainerFile[] = [];

export default function trainersFilesReducer(
    state = initialState,
    {
        payload,
        type,
    }:
        | PayloadAction<
              typeof initialState,
              typeof FETCH_TRAINERS_FILES_REQUEST | typeof FETCH_TRAINERS_FILES_REQUEST_SUCCESS | typeof FETCH_TRAINERS_FILES_REQUEST_FAILED
          >
        | PayloadAction<
              { exerciseId: string; gender: 'Male' | 'Female'; trainerId: string; updatedFiles: Files.Asset[] },
              typeof APPROVE_TRAINER_FILE | typeof DENIED_TRAINER_FILE
          >,
) {
    switch (type) {
        case 'FETCH_TRAINERS_FILES_REQUEST':
            return state;
        case 'FETCH_TRAINERS_FILES_REQUEST_SUCCESS':
            return payload;
        case 'FETCH_TRAINERS_FILES_REQUEST_FAILED':
            return state;
        // case 'APPROVE_TRAINER_FILE':
        //     return produce(state, (draftState) => {
        //         const idx = state.findIndex((file) => file.exerciseUid === payload.exerciseId && file.trainerUid === payload.trainerId);
        //         const assetsPathUrls = payload.gender === 'Male' ? 'manAssetsUrls' : 'womanAssetsUrls';

        //         draftState[idx][assetsPathUrls] = payload.updatedFiles;
        //     });
        // case 'DENIED_TRAINER_FILE':
        //     return produce(state, (draftState) => {
        //         const idx = state.findIndex((file) => file.exerciseUid === payload.exerciseId && file.trainerUid === payload.trainerId);
        //         const assetsPathUrls = payload.gender === 'Male' ? 'manAssetsUrls' : 'womanAssetsUrls';

        //         draftState[idx][assetsPathUrls] = payload.updatedFiles;
        //     });
        default:
            return state;
    }
}

export const fetchTrainersFiles = function* (): Generator<
    | PutEffect<PayloadAction<typeof initialState, typeof FETCH_TRAINERS_FILES_REQUEST_SUCCESS> | Action<typeof FETCH_TRAINERS_FILES_REQUEST_FAILED>>
    | Promise<QuerySnapshot<DocumentData>>,
    void,
    QuerySnapshot<DocumentData>
> {
    const ref = collectionGroup(firestore, 'trainersFiles');
    try {
        const query = yield getDocs(ref);

        if (query.empty) {
            yield put({ type: 'FETCH_TRAINERS_FILES_REQUEST_FAILED' });

            return;
        }

        let files: Files.TrainerFile[] = [];

        for (let index = 0; index < query.docs.length; index++) {
            const file = query.docs?.[index].data() as Files.TrainerFile;

            files.push(file);
        }

        yield put({ type: 'FETCH_TRAINERS_FILES_REQUEST_SUCCESS', payload: files });

        return;
    } catch (error) {
        console.log(error);
    }
};

export const approveTrainerFile = function* (
    action: PayloadAction<
        { exerciseId: string; fileIndex: number; files: Files.Asset[]; gender: 'Male' | 'Female'; trainerId: string },
        typeof APPROVE_TRAINER_FILE
    >,
): Generator<
    | PutEffect<
          PayloadAction<
              { exerciseId: string; gender: 'Male' | 'Female'; trainerId: string; updatedFiles: Files.Asset[] },
              typeof APPROVE_TRAINER_FILE
          >
      >
    | Promise<void>,
    void
> {
    const {
        payload: { exerciseId, fileIndex, files, gender, trainerId },
    } = action;
    const assetsPathUrls = gender === 'Male' ? 'manAssetsUrls' : 'womanAssetsUrls';
    const ref = doc(firestore, 'exercises', exerciseId, 'trainersFiles', trainerId);

    const updatedFiles = produce(files, (draftState) => {
        if (typeof draftState[fileIndex].isProcessed !== 'undefined') {
            delete draftState[fileIndex].isProcessed;
        }
    });

    try {
        yield updateDoc(ref, {
            [assetsPathUrls]: updatedFiles,
        });

        yield put({ type: 'APPROVE_TRAINER_FILE', payload: { exerciseId, gender, trainerId, updatedFiles } });
        return;
    } catch (error) {
        console.log(error);
    }
};

export const deniedTrainerFile = function* (
    action: PayloadAction<{
        exerciseId: string;
        fileIndex: number;
        files: Files.Asset[];
        gender: 'Male' | 'Female';
        isDenieded: boolean;
        reviewComment: string;
        trainerId: string;
    }>,
): Generator<
    | PutEffect<
          PayloadAction<{ exerciseId: string; gender: 'Male' | 'Female'; trainerId: string; updatedFiles: Files.Asset[] }, typeof DENIED_TRAINER_FILE>
      >
    | Promise<void>,
    void
> {
    const {
        payload: { exerciseId, fileIndex, files, gender, isDenieded, reviewComment, trainerId },
    } = action;
    const assetsPathUrls = gender === 'Male' ? 'manAssetsUrls' : 'womanAssetsUrls';
    const ref = doc(firestore, 'exercises', exerciseId, 'trainersFiles', trainerId);

    const updatedFiles = produce(files, (draftState) => {
        draftState[fileIndex].isDenieded = isDenieded;
        draftState[fileIndex].reviewComment = reviewComment;
    });
    try {
        yield updateDoc(ref, {
            [assetsPathUrls]: updatedFiles,
        });

        yield put({ type: 'DENIED_TRAINER_FILE', payload: { exerciseId, gender, trainerId, updatedFiles } });

        return;
    } catch (error) {
        console.log(error);
    }
};

// selectors
export const selectTrainerFiles = createSelector(
    (state: RootState) => state.trainersFiles,
    (files) => files,
);
