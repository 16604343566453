import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer/rootReducer";
import rootSaga from "./v2/sagas";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

const sagaMiddleware = createSagaMiddleware();

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ immutableCheck: false, serializableCheck: false, thunk: true }).concat(sagaMiddleware),
});
sagaMiddleware.run(rootSaga);
