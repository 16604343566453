import { Box, CircularProgress } from "@mui/material";
import { ReactElement, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { useAuthHandler } from "../app/common/util/customHooks";
import SignIn from "../components/auth/SignIn";
import Dashboard from "../theme/layouts/Dashboard";
import Welcome from "../welcome/Welcome";

export const routes = [
    {
        name: "Exercises",
        path: "/exercises/",
    },
    // {
    //     name: "Workouts",
    //     path: "/workouts/",
    // },
    {
        name: "Programs",
        path: "/programs",
    },
    // {
    //     name: "Guides",
    //     path: "/guides",
    // },
    // {
    //     name: "Recipes",
    //     path: "/recipes",
    // },
    // // {
    //     name: "Ingredients",
    //     path: "/ingredients",
    // },
    {
        name: "Trainers",
        path: "/trainers",
    },
    {
        name: "Teams",
        path: "/teams",
    },
    // {
    //     name: "Feed",
    //     path: "/feed",
    // },
    {
        name: "Users",
        path: "/users",
    },
    // {
    //     name: "Finance",
    //     path: "/finance",
    // },
    // {
    //     path: "/statistics/programs/",
    //     name: "Programs Stats",
    // },
    // {
    //     path: "/statistics/workout-ratings/",
    //     name: "Workout Ratings Stats",
    // },
    {
        path: "/utils/citations-manager",
        name: "Citations Manager",
    },
    {
        path: "/utils/discovery-content-manager",
        name: "Discovery Content Manager",
    },
    // {
    //     path: "/utils/file-manager",
    //     name: "File Manager",
    // },
    // {
    //     path: "/utils/taguploader",
    //     name: "Tag Uploader",
    // },
    // {
    //     path: "/utils/workout-section-cover-uploader",
    //     name: "Workout Section Cover Uploader",
    // },
    // {
    //     path: "/utils/firebase-remote-config",
    //     name: "Firebase Remote Config",
    // },
];

// Exercise components
const ExerciseDashboard = lazy(() => import("../components/exercises/ExerciseDashboard"));
const ExerciseForm = lazy(() => import("../components/exercises/exerciseForm/ExerciseForm"));
const UploadTrainerFilesDashboard = lazy(
    () => import("../components/exercises/uploadTrainerFilesDashboard/UploadTrainerFilesDashboard")
);
const ExerciseDescription = lazy(() => import("../components/exercises/exerciseDescription/ExerciseDescription"));

// Team Components
const TeamsDashboard = lazy(() => import("../components/teams/teamsDashboard/TeamsDashboard"));
const TeamLanding = lazy(() => import("../components/teams/teamLanding/Home"));

// Feed
const Feed = lazy(() => import("../components/feed/Feed"));

// Users
const UsersDashboard = lazy(() => import("../components/users/UsersDashboard"));
const TrainersDashboard = lazy(() => import("../components/trainers/trainersDashboard/TrainersDashboard"));

// Workout components
const WorkoutCategories = lazy(() => import("../components/workouts/workoutCategories/WorkoutCategoriesDashboard"));
const WorkoutBuilder = lazy(() => import("../components/workouts/workoutBuilder/WorkoutBuilder"));
const WorkoutDetailsPage = lazy(() => import("../components/workouts/workoutDetails/WorkoutDetailsPage"));

// Utils coponenst
const CitationsManager = lazy(() => import("../components/utils/citations/CitationsManager"));
const DiscoveryContentManager = lazy(
    () => import("../components/utils/discoveryContentManager/DiscoveryContentManager")
);
const FileManager = lazy(() => import("../components/utils/fileManager/FileManagerDashboard"));
const FileManagerFolder = lazy(() => import("../components/utils/fileManager/FileManagerFolder"));
const TagUploader = lazy(() => import("../components/utils/tagUploader/TagUploader"));
const WorkoutSectionCoverUploader = lazy(
    () => import("../components/utils/workoutSectionCoverUploader/WorkoutSectionCoverUploaderDashboard")
);
const FirebaseRemoteConfig = lazy(() => import("../components/utils/firebaseRemoteConfig/FirebaseRemoteConfig"));

// Programs
const ProgramBuilder = lazy(() => import("../components/programs/programBuilder/ProgramBuilder"));
const ProgramsDashboard = lazy(() => import("../components/programs/programDashboard/ProgramDashboard"));
const ProgramForm = lazy(() => import("../components/programs/programForm/ProgramForm"));

// Guides
const Guides = lazy(() => import("../components/guides/Guides"));
const GuideForm = lazy(() => import("../components/guides/GuideForm"));

// Finance
const FinanceDashboard = lazy(() => import("../components/finance/FinanceDashboard"));
const InvoicesList = lazy(
    () => import(/* webpackChunkName: InvoicesList */ "../components/finance/invoices/InvoicesList")
);

// Recipes
const Recipes = lazy(() => import("../components/recipes/Recipes"));
const AddRecipe = lazy(() => import("../components/recipes/RecipeForm/AddRecipe"));
const Ingredients = lazy(() => import("../components/recipes/Ingredients"));

// Statistics
const Programs = lazy(() => import("../components/statistics/programs/Programs"));
const WorkoutRatings = lazy(() => import("../components/statistics/workoutRatings/WorkoutRatings"));

interface RoutesProps {}

function Routes({}: RoutesProps): ReactElement {
    const { currentUser } = useAuthHandler();

    return (
        <Suspense
            fallback={
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            }
        >
            {currentUser ? (
                <Dashboard>
                    <Switch>
                        <Route exact path="/" component={Welcome} />
                        {/* Exercises */}
                        <Route exact path="/exercises" component={ExerciseDashboard} />
                        <Route exact path="/exercise-builder/create/" component={ExerciseForm} />
                        <Route exact path="/exercise-builder/edit/:id" component={ExerciseForm} />
                        <Route exact path="/trainers/assets-uploader/:id" component={UploadTrainerFilesDashboard} />
                        <Route exact path="/exercises/exercise-description/:id" component={ExerciseDescription} />
                        {/* Teams */}
                        <Route exact path="/teams" component={TeamsDashboard} />
                        <Route exact path="/teams/landing-editor/:id" component={TeamLanding} />
                        {/* Feed */}
                        <Route exact path="/feed" component={Feed} />
                        {/* Users */}
                        <Route exact path="/users" component={UsersDashboard} />
                        <Route exact path="/trainers" component={TrainersDashboard} />
                        {/* Workouts */}
                        <Route exact path="/workouts" component={WorkoutCategories} />
                        <Route exact path="/workout-builder/create" component={WorkoutBuilder} />
                        <Route exact path="/workout-builder/edit/:id" component={WorkoutBuilder} />
                        <Route exact path="/workouts/workout-details/:id" component={WorkoutDetailsPage} />
                        {/* Utils */}
                        <Route exact path="/utils/citations-manager" component={CitationsManager} />
                        <Route exact path="/utils/discovery-content-manager" component={DiscoveryContentManager} />
                        <Route exact path="/utils/file-manager" component={FileManager} />
                        <Route exact path="/utils/file-manager/:foldername" component={FileManagerFolder} />
                        <Route exact path="/utils/taguploader" component={TagUploader} />
                        <Route exact path="/utils/firebase-remote-config" component={FirebaseRemoteConfig} />
                        <Route
                            exact
                            path="/utils/workout-section-cover-uploader"
                            component={WorkoutSectionCoverUploader}
                        />
                        {/* Programs */}
                        <Route exact path="/program-builder/builder/:id" component={ProgramBuilder} />
                        <Route exact path="/programs" component={ProgramsDashboard} />
                        <Route exact path="/program-builder/create/" component={ProgramForm} />
                        <Route exact path="/program-builder/edit/:id" component={ProgramForm} />
                        {/* Guides, Recipes */}
                        <Route exact path="/guides" component={Guides} />
                        <Route exact path="/guides/:id" component={GuideForm} />
                        <Route exact path="/recipes" component={Recipes} />
                        <Route exact path="/recipes/:id" component={AddRecipe} />
                        <Route exact path="/ingredients" component={Ingredients} />
                        {/* Stats */}
                        <Route exact path="/finance" component={FinanceDashboard} />
                        <Route exact path="/finance/invoices/:teamId" component={InvoicesList} />
                        <Route exact path="/statistics/programs" component={Programs} />
                        <Route exact path="/statistics/workout-ratings" component={WorkoutRatings} />
                    </Switch>
                </Dashboard>
            ) : (
                <Switch>
                    <Route exact path="/auth/signin" component={SignIn} />
                </Switch>
            )}
        </Suspense>
    );
}

export default Routes;
