import React, { ComponentType, ReactElement, Suspense, useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { spacing } from '@material-ui/system';
import { Hidden, CssBaseline, Paper as MuiPaper, withWidth, CircularProgress } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/styles';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props: any) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
  
  *:focus {
    outline: none;
    }
`;

const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up('md')} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
    background: ${(props) => props.theme.body.background};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }

    .MuiPaper-root .MuiPaper-root {
        box-shadow: none;
    }
`;

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    topBar: {
        zIndex: 2,
        position: 'relative',
    },
    container: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    navBar: {
        zIndex: 3,
        width: 256,
        minWidth: 256,
        flex: '0 0 auto',
    },
    content: {
        overflowY: 'auto',
        flex: '1 1 auto',
    },
}));

function Dashboard({ children, routes, width }: any): ReactElement {
    const firebase = useFirebase();
    const history = useHistory<any>();
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GlobalStyle />
            <Header onDrawerToggle={handleDrawerToggle} firebase={firebase} history={history} />

            <div className={classes.container}>
                <Drawer>
                    <Hidden mdUp implementation="js">
                        <Sidebar
                            routes={routes}
                            PaperProps={{ style: { width: drawerWidth } }}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
                    </Hidden>
                </Drawer>
                <Suspense
                    fallback={
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress />
                        </div>
                    }
                >
                    <main className={classes.content}>
                        <MainContent p={isWidthUp('lg', width) ? 10 : 5}>{children}</MainContent>
                    </main>
                </Suspense>
            </div>
            <Footer />
        </div>
    );
}

export default withWidth()(Dashboard);
