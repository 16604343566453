import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import '../vendor/perfect-scrollbar.css';
import { spacing } from '@material-ui/system';
import { Avatar, Badge, Box as MuiBox, Drawer as MuiDrawer, Grid, List as MuiList, ListItem, Typography } from '@material-ui/core';
import { Layers } from 'react-feather';
import { useTypedSelector } from '../..';
import { routes } from '../../routes/Routes';

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`;

const Scrollbar = styled(PerfectScrollbar)`
    background-color: ${(props) => props.theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
    background-color: ${(props) => props.theme.sidebar.background};
`;

const Brand: any = styled(ListItem)`
    font-size: ${(props) => props.theme.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.sidebar.header.color};
    background-color: ${(props) => props.theme.sidebar.header.background};
    font-family: ${(props) => props.theme.typography.fontFamily};
    min-height: 56px;
    padding-left: ${(props) => props.theme.spacing(6)}px;
    padding-right: ${(props) => props.theme.spacing(6)}px;

    ${(props) => props.theme.breakpoints.up('sm')} {
        min-height: 64px;
    }
`;

const BrandIcon = styled(Layers)`
    margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const SidebarFooter = styled.div`
    background-color: ${(props) => props.theme.sidebar.footer.background} !important;
    padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
    color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
    color: ${(props) => props.theme.sidebar.footer.color};
    font-size: 0.725rem;
    display: block;
    padding: 1px;
`;

const StyledBadge = styled(Badge)`
    margin-right: ${(props) => props.theme.spacing(1)}px;

    span {
        background-color: ${(props) => props.theme.sidebar.footer.online.background};
        border: 1.5px solid ${(props) => props.theme.palette.common.white};
        height: 12px;
        width: 12px;
        border-radius: 50%;
    }
`;

function Sidebar({ classes, staticContext, ...rest }: any) {
    const user = useTypedSelector((state) => state.firebase.profile);

    return (
        <Drawer variant="permanent" {...rest}>
            <Brand>
                <BrandIcon />
                <Box ml={1}>NOEX Training</Box>
            </Brand>
            <Scrollbar>
                <List disablePadding>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {routes.map((route, index) => (
                            <NavLink to={route.path} key={index} activeClassName="active">
                                <Typography variant="h4" style={{ color: '#ffffff', padding: 8 }}>
                                    {route.name}
                                </Typography>
                            </NavLink>
                        ))}
                    </div>
                </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container spacing={2}>
                    <Grid item>
                        <StyledBadge
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            <Avatar alt={`${user.name}`} src={user.facebookProfilePictureUrl} />
                        </StyledBadge>
                    </Grid>
                    <Grid item>
                        <SidebarFooterText variant="body2">{user.name}</SidebarFooterText>
                        <SidebarFooterSubText variant="caption">NOEX Admin</SidebarFooterSubText>
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    );
}

export default Sidebar;
