// typed custom filter V2
export function customTypedFilter<T>(fn: (item: T) => boolean, array: T[]): T[] {
    const f = [];
    for (let i = 0; i < array?.length; i++) {
        if (fn(array[i])) {
            f.push(array[i]);
        }
    }
    return f;
}
