import { onAuthStateChanged, User } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../../firebase/firebase";
import _ from "lodash";
import { Action, Dispatch } from "redux";
import { useAppDispatch } from "../../..";
import { PayloadAction } from "../../../store/typedefinitions/typedReduxHooks";
import { SET_PROGRAMS_LISTENER } from "../../../store/v2/programs";
import { SET_GUIDES_LISTENER } from "../../../store/v2/guides";
import { SET_GUIDE_SECTIONS_LISTENER } from "../../../store/v2/guideSections";

export function useAuthHandler() {
    const history = useHistory();
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    useEffect(() => {
        onAuthStateChanged(
            auth,
            (user) => {
                setCurrentUser(user);
                if (_.isEmpty(user) || user === null) {
                    history.push("/auth/signin");
                } else {
                    history.push("/");
                }
            },
            (error) => console.log(error)
        );
    }, [auth]);

    return {
        currentUser,
    };
}

export function useSagaListener(
    type: typeof SET_PROGRAMS_LISTENER | typeof SET_GUIDES_LISTENER | typeof SET_GUIDE_SECTIONS_LISTENER,
    deps: any[],
    payload?: any
) {
    const dispatch: Dispatch<PayloadAction<typeof payload, typeof type> | Action<typeof type>> = useAppDispatch();

    const ls = useCallback(() => {
        if (typeof payload === "undefined") {
            dispatch({ type });
        } else {
            dispatch({ payload, type });
        }
    }, [...deps]);

    useEffect(() => {
        ls();

        return () => {
            ls();
        };
    }, [ls]);
}
