import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase/firebase";

// refractored
export const login = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.log(error);
    }
};
