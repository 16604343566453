import { Fragment, ReactElement } from "react";
import { useSelector, useStore } from "react-redux";
import { useFirebaseConnect, useFirestoreConnect } from "react-redux-firebase";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import Helmet from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import { RootState } from "./store/rootReducer/rootReducer";
import maTheme from "./theme/theme";
import Routes from "./routes/Routes";
import { useSagaListener } from "./app/common/util/customHooks";
import { firestore } from "./firebase/firebase";

function App(): ReactElement {
    const theme = useSelector((state: RootState) => state.theme);
    useSagaListener("SET_PROGRAMS_LISTENER", [firestore]);
    useSagaListener("SET_GUIDES_LISTENER", [firestore]);
    useSagaListener("SET_GUIDE_SECTIONS_LISTENER", [firestore]);
    useFirebaseConnect([
        {
            path: "ingredients",
            storeAs: "ingredients",
        },
    ]);
    useFirestoreConnect([
        { collection: "exercises", orderBy: ["exerciseNumber", "asc"], storeAs: "exercises" },
        { collection: "profiles", where: [["isTrainer", "==", true]], orderBy: ["name", "asc"], storeAs: "trainers" },
        { collection: "recipes", storeAs: "recipes" },
        { collection: "tagTypes", storeAs: "tagTypes" },
        { collection: "teams", orderBy: ["name", "asc"], storeAs: "teams" },
        { collection: "workoutCategories", storeAs: "workoutCategories", orderBy: ["order", "asc"] },
        { collection: "workouts", storeAs: "workouts", orderBy: ["sectionOrder", "asc"] },
        { collection: "workoutSections", storeAs: "workoutSections", orderBy: ["order", "asc"] },
        { collection: "teamsLandingTemplates", storeAs: "teamsLandingTemplates" },
        { collection: "citations", storeAs: "citations" },
        { collection: "discoveryContent", storeAs: "discoveryContent" },
        { collection: "fileManager", orderBy: ["folderName", "asc"], storeAs: "fileManager" },
        {
            collection: "fileManager",
            doc: "147a2377-4204-4e38-804c-2192794310d2",
            subcollections: [{ collection: "files" }],
            storeAs: "workoutHeaders",
        },
        {
            collection: "fileManager",
            doc: "d5517a44-4253-468c-8723-d0bade94c167",
            subcollections: [{ collection: "files" }],
            storeAs: "workoutThumbs",
        },
    ]);
    return (
        <Fragment>
            <Helmet titleTemplate="%s | NOEX Training" defaultTitle="NOEX Training Admin" />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
                        <ThemeProvider theme={maTheme[theme.currentTheme]}>
                            <Routes />
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </Fragment>
    );
}

export default App;
